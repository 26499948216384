import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StimulationModal = ({ open, onClose }) => {
  const [openStimulationModal, setOpenStimulationModal] = useState(false);
  const [error, setError] = useState(false);

  const location = useLocation();

  const username = location.state.name;
  const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
  const yearNumber = parseInt(localStorage.getItem("currentYear"));
  const today = new Date();

  const stimulationValue = useRef(null);

  const saveStimulation = async () => {
    const stimulationValueInput =
      stimulationValue.current.querySelector("input").value;

    console.log(stimulationValueInput);

    if (stimulationValueInput !== "") {
      setError(false);

      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/approval-hours/approve`, {
          username: username,
          month: monthNumber,
          year: yearNumber,
          date: today,
          stimulation: stimulationValueInput,
        })
        .then(async (response) => {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
            username: username,
            employee: location.state.name,
            date: today,
            action: "stimulation added",
            system: false,
          });

          setOpenStimulationModal(false);
          handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (open) {
      setOpenStimulationModal(true);
    } else {
      setOpenStimulationModal(false);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    setOpenStimulationModal(false);
  };

  return (
    <BootstrapDialog
      open={openStimulationModal}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      className="more-options-dialog md:w-1/2 m-auto"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Korekcija malic
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <div>
          <TextField
            error={error}
            required
            id="outlined-required"
            type="number"
            label="Vpiši % stimulacije"
            variant="filled"
            className="w-full"
            ref={stimulationValue}
          />
          <div className="mt-2">
            <Button
              className="btn w-full primary-btn"
              onClick={saveStimulation}
            >
              Potrdi
            </Button>
          </div>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default StimulationModal;
