import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { useSelector } from "react-redux";
import "dayjs/locale/sl";
import { selectAllEntries } from "../../store/redux/slices/entriesSlice";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { fontSize } from "@mui/system";
dayjs.locale("sl"); // use locale globally
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function Day({ day, rowIdx }) {
  const { setDaySelected, setShowEventModal, setSelectedEvent } =
    useContext(GlobalContext);

  const entries = useSelector(selectAllEntries);

  const matchingEntries = entries.filter(
    (evt) =>
      dayjs(evt.day).utc().format("DD-MM-YY") ===
      dayjs(day).utc().format("DD-MM-YY")
  );

  const isHoliday = matchingEntries.some((existingEntry) => existingEntry.drp);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-green-50 rounded-full"
      : "";
  }

  function getCurrentDayClassRed() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-red text-white rounded-full w-7"
      : "";
  }
  return (
    <div
      className={`${
        dayjs(day).day() == 0 ? "bg-gray-50" : ""
      } ${getCurrentDayClass()} ${
        isHoliday ? "bg-gray-50 cursor-not-allowed" : "cursor-pointer"
      } border border-gray-200 flex flex-row md:flex-col day relative`}
      id={day.format("DD")}
    >
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <p className="text-sm mt-1 hidden md:block">{day.format("ddd")}</p>
        )}
        <p className="text-sm mt-1 md:hidden">{day.format("ddd")}</p>
        <p
          className={`text-sm p-1 my-1 md:mb-2 text-center m-auto mb-0 md:hidden ${getCurrentDayClassRed()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <div
        className="flex-1 cursor-pointer mh-20"
        onClick={() => {
          if (!isHoliday) {
            if (entries.length === 0) {
              if (dayjs(day).day() !== 0) {
                setDaySelected(day);
                setShowEventModal(true);
              }
            } else {
              entries.forEach((element) => {
                if (
                  dayjs(element.date).format("DD-MM-YY") !==
                  day.format("DD-MM-YY")
                ) {
                  if (dayjs(day).day() !== 0) {
                    setDaySelected(day);
                    setShowEventModal(true);
                    document.body.style.overflow = "hidden";
                  }
                }
              });
            }
          }
        }}
      >
        <p
          className={`text-sm p-1 my-1 mb-2 text-center m-auto hidden md:block ${getCurrentDayClassRed()}`}
        >
          {day.format("DD")}
        </p>

        {matchingEntries.map((existingEntry, idx) => (
          <div key={idx}>
            <div className="flex justify-evenly items-center">
              {existingEntry.startTime && (
                <div className="pl-5 md:pl-0">
                  {existingEntry.startTime && (
                    <p className="text-xs text-left prihod-value">
                      <span>Prihod: </span>
                      {existingEntry.startTime != null
                        ? dayjs(existingEntry.startTime).utc().format("HH:mm")
                        : ""}
                    </p>
                  )}
                  {existingEntry.endTime && (
                    <p className="text-xs text-left odhod-value">
                      <span>Odhod: </span>
                      {existingEntry.endTime
                        ? dayjs(existingEntry.endTime).utc().format("HH:mm")
                        : ""}
                    </p>
                  )}
                </div>
              )}

              {existingEntry.notes && (
                <div>
                  <TextSnippetIcon sx={{ fontSize: 22 }} className="toggleButton" />
                </div>
              )}
            </div>
            <div>
              {existingEntry.rdc !== null &&
                !isNaN(existingEntry.rdc) &&
                existingEntry.rdc > 0 && (
                  <span className="text-xs bg-green-300 inline-block px-2">
                    {existingEntry.rdc}
                  </span>
                )}

              {existingEntry.rdv !== null &&
                !isNaN(existingEntry.rdv) &&
                existingEntry.rdv > 0 && (
                  <span className="text-xs bg-green-100 inline-block px-2">
                    {existingEntry.rdv}
                  </span>
                )}

              {existingEntry.nadure !== null &&
                !isNaN(existingEntry.nadure) &&
                existingEntry.nadure > 0 && (
                  <span className="text-xs bg-red-100 inline-block px-2">
                    {existingEntry.nadure}
                  </span>
                )}

              {existingEntry.koriscenje !== null &&
                !isNaN(existingEntry.koriscenje) &&
                existingEntry.koriscenje > 0 && (
                  <span className="text-xs bg-gray-200 inline-block px-2">
                    {existingEntry.koriscenje}
                  </span>
                )}
            </div>
            {existingEntry.drp && (
              <p className="text-red-300 text-xs">Dela prost dan</p>
            )}
            {existingEntry.dop && (
              <p className="text-red-300 text-xs">Dopust {existingEntry.dop < 8 ? ' - ' + existingEntry.dop + ' h' : ''} </p>
            )}
            {existingEntry.bp8 && (
              <p className="text-red-300 text-xs">Bolniški stalež {existingEntry.bp8 < 8 ? ' - ' + existingEntry.bp8 + ' h' : ''}</p>
            )}

            <div
              onClick={() => {
                if (!isHoliday) {
                  if (dayjs(day).day() != 0) {
                    setSelectedEvent(existingEntry);
                    setShowEventModal(true);
                  }
                }
              }}
              className={`p-1 mr-3 text-gray-600 text-sm rounded mb-1 truncate pl-4 md:pl-0 absolute top-0 left-0 w-full h-full`}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
