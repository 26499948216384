import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getMonth } from "../../util";
import Header from "../Header/Header";
import GlobalContext from "../../context/GlobalContext";
import { DateTime } from "../Shared/DateTime";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import "../../App.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { getRemainingVacation } from "../../services/Vacation";
import { useMsal } from "@azure/msal-react";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Check = () => {
  const today = new Date();

  const targetTimeUTC = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours(),
      today.getUTCMinutes(),
      today.getUTCSeconds()
    )
  );

  targetTimeUTC.setUTCHours(
    today.getHours(),
    today.getMinutes(),
    today.getSeconds(),
    today.getMilliseconds()
  );

  const { monthIndex, showEventModal } = useContext(GlobalContext);

  const [currenMonth, setCurrentMonth] = useState(getMonth(monthIndex));
  const [lunchTimeStatus, setLunchTimeStatus] = useState(true);
  const [moreOptionsStatus, setMoreOptionsStatus] = useState(true);
  const [startTimeStatus, setStartTimeStatus] = useState(true);
  const [endTimeStatus, setEndTimeStatus] = useState(true);

  const [todayEntry, setTodayEntry] = useState(null);
  const [workMode, setWorkMode] = useState("");
  const [greatings, setGreatings] = useState("Pozdravljeni");

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [lunchTime, setLunchTime] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [manualTimeEntry, setManualTimeEntry] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vacationDays, setVacationDays] = useState(0);

  const user = useSelector((state) => state.msalInstance.user);
  const username = user.username;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const addHoliday = async () => {
    today.setUTCHours(0, 0, 0, 0);

    const day = {
      date: today,
      username: username,
      day: today.valueOf(),
      entry_id: Date.now(),
      dop: 8,
    };

    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries/holiday",
      day
    );

    getTodayEntry();

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "added holiday (homepage)",
      day: today.valueOf(),
      system: false,
    });
  };

  const addSickDay = async () => {
    today.setUTCHours(0, 0, 0, 0);

    const day = {
      date: today,
      username: username,
      day: today.valueOf(),
      entry_id: Date.now(),
      bp8: 8,
    };

    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries/sickday",
      day
    );

    getTodayEntry();

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "added sickday (homepage)",
      day: today.valueOf(),
      system: false,
    });
  };

  const startWorkingDay = async () => {
    today.setUTCHours(0, 0, 0, 0);

    const day = {
      date: today,
      username: username,
      startTime: targetTimeUTC,
      day: today.valueOf(),
      entry_id: Date.now(),
    };

    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries",
      day
    );

    getTodayEntry();

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "started work",
      day: today.valueOf(),
      system: false,
    });
  };

  const endWorkingDay = async () => {
    const day = {
      username: username,
      endTime: targetTimeUTC,
    };

    const response = await axios.patch(
      process.env.REACT_APP_API_URL + `/entries`,
      {
        entry_id: todayEntry.entry_id,
        username: username,
        endTime: targetTimeUTC,
      }
    );

    getTodayEntry();

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "ended work",
      day: today.valueOf(),
      system: false,
    });
  };

  const addLunchTime = async () => {

    const lunchTimeEnd = new Date(targetTimeUTC);
    lunchTimeEnd.setUTCMinutes(targetTimeUTC.getUTCMinutes() + 30);

    const response = await axios.patch(
      process.env.REACT_APP_API_URL + `/entries/lunch`,
      {
        entry_id: todayEntry.entry_id,
        username: username,
        lunchTime: targetTimeUTC,
        lunchTimeEnd: lunchTimeEnd,
      }
    );

    getTodayEntry();

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
      username: user.username,
      date: today,
      action: "lunch added",
      day: today.valueOf(),
      system: false,
    });
  };

  const getTodayEntry = () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/entries/getTodayEntry/${username}`)
      .then((response) => {
        if (response) {
          setTodayEntry(response.data);

          if (response.data) {
            //Check free day
            if (response.data.dop || response.data.bp8) {
              setEndTimeStatus(true);
              setStartTimeStatus(true);
              setMoreOptionsStatus(true);
            }
          }

          //Check lunchtime option
          if (
            response.data === null ||
            response.data.endTime !== undefined ||
            response.data.lunchTime ||
            response.data.dop !== undefined ||
            response.data.bp8 !== undefined
          ) {
            
            setLunchTimeStatus(true);
          } else {
            setLunchTimeStatus(false);
          }
        }

        if (response.data !== null) {
          //Check more button status
          if (response.data.startTime == undefined) {
            setMoreOptionsStatus(false);
          } else {
            setMoreOptionsStatus(true);
          }

          //Disable buttons depends on start and end time
          if (response.data.dop !== undefined) {
            setWorkMode("Dopust");
            setMoreOptionsStatus(true);
          } else if (response.data.bp8 !== undefined) {
            setWorkMode("Bolniška odsotnost");
            setMoreOptionsStatus(true);
          } else if (
            response.data.startTime !== null &&
            response.data.endTime == undefined
          ) {
            setStartTimeStatus(true);
            setEndTimeStatus(false);
            setStartTime(response.data.startTime);
          } else if (response.data.endTime == undefined) {
            setStartTimeStatus(false);
          } else if (
            response.data.endTime !== null &&
            response.data.startTime !== null
          ) {
            setEndTimeStatus(true);
            setStartTimeStatus(true);
            setStartTime(response.data.startTime);
            setEndTime(response.data.endTime);
          }

          if (
            response.data.lunchTime != null ||
            response.data.lunchTime != undefined
          ) {
            setLunchTime(response.data.lunchTime);
          }
        } else {
          if (today.getDay() == 0) {
            setEndTimeStatus(true);
            setStartTimeStatus(true);
            setLunchTimeStatus(true);
            setMoreOptionsStatus(true);
          } else {
            setStartTimeStatus(false);
            setMoreOptionsStatus(false);
          }
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  const checkGreetingsType = () => {
    if (dayjs(today).hour() >= 5 && dayjs(today).hour() <= 9) {
      setGreatings("Dobro jutro");
    } else {
      setGreatings("Pozdravljeni");
    }
  };

  useEffect(() => {
    checkGreetingsType();

    currenMonth.forEach((el) => {
      if (dayjs(el[0]).format("DD-MM-YY") === dayjs(today).format("DD-MM-YY")) {
      }
    });

    getTodayEntry();
  }, []);

  const { instance } = useMsal();

  useEffect(() => {
    const VacationDays = async () => {
      const vacationDays = await getRemainingVacation(username);
      setVacationDays(vacationDays);
    };
    VacationDays();
  }, []);

  return (
    <div>
      <Header />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="content-wrapper pt-67">
          <div className="flex flex-col items-center justify-between max-w-xl m-auto custom-vh">
            <div className="mb-10 h-full flex flex-col justify-center">
              <DateTime />
              <h1 className="text-xl mb-4 font-light">
                {greatings} {user.name}
              </h1>

              <h2
                className={`text-sm  ${workMode != "" ? "text-red-600" : ""}`}
              >
                {startTime !== null
                  ? "Prihod • " + dayjs(startTime).utc().format("HH:mm")
                  : ""}
                {lunchTime !== null
                  ? " • Malica • " + dayjs(lunchTime).utc().format("hh:mm")
                  : ""}
                {endTime !== null
                  ? " • Odhod • " + dayjs(endTime).utc().format("HH:mm")
                  : ""}

                {workMode !== "" ? workMode : ""}
              </h2>
            </div>

            <div className="flex flex-col items-center w-full mt-10">
              <div className="grid grid-cols-2 gap-2 w-full p-2">
                <Button
                  className="btn w-full green-btn"
                  variant="contained"
                  onClick={startWorkingDay}
                  disabled={startTimeStatus}
                >
                  PRIHOD
                </Button>

                <Button
                  className="btn w-full primary-btn"
                  variant="contained"
                  onClick={endWorkingDay}
                  disabled={endTimeStatus}
                >
                  ODHOD
                </Button>
                <Button
                  className="btn w-full secondary-btn"
                  variant="contained"
                  disabled={lunchTimeStatus}
                  onClick={addLunchTime}
                >
                  MALICA
                </Button>
                <Button
                  className="btn w-full secondary-btn"
                  variant="contained"
                  onClick={handleOpen}
                  disabled={moreOptionsStatus}
                >
                  VEČ <ArrowDropDownIcon />
                </Button>
              </div>
              <div>
                <p className="p-2 text-sm font-light">
                  <a className="text-red-500" href="https://ure.termo-plus.eu/TS_Navodila_za_uporabo_spletne_aplikacije_Termo_Shop_evidenca_ur.pdf" rel="noopener noreferrer">
                    Navodila za uporabo
                  </a>
                  <Link to="/zgodovina">
                    {" "}
                    • Ste pozabili zabeležiti prihod ali odhod? Kliknite {" "}
                    <span className="underline-red"> tukaj </span>
                  </Link>
                </p>
              </div>
            </div>

            <BootstrapDialog
              open={open}
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              className="more-options-dialog"
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Dodatne možnosti
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers>
                <div className="mb-5">
                  <p>
                    Stanje letnega dopusta:{" "}
                    <span className="font-bold">{vacationDays} </span>
                  </p>
                </div>
                <div className="mb-1">
                  <Button
                    className="btn w-full secondary-btn"
                    variant="contained"
                    onClick={addHoliday}
                  >
                    Dopust
                  </Button>
                </div>

                <div>
                  <Button
                    className="btn w-full secondary-btn"
                    variant="contained"
                    onClick={addSickDay}
                  >
                    Bolniški stalež
                  </Button>
                </div>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </div>
      )}
    </div>
  );
};
export default Check;
