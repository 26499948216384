import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./Calendar.css";
import CalendarHeader from "./CalendarHeader";
import Month from "./Month";
import Sidebar from "./Sidebar";
import { getMonth } from "../../util";
import GlobalContext from "../../context/GlobalContext";
import EventModal from "./EventModal";
import { selectAllEntries } from "../../store/redux/slices/entriesSlice";

const Calendar = () => {
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const [currenMonth, setCurrentMonth] = useState(getMonth(monthIndex));
  const entries = useSelector(selectAllEntries);
  const location = useLocation();

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  useEffect(() => {
    localStorage.setItem("currentMonth", currenMonth[1][0].month());
    localStorage.setItem("currentYear", currenMonth[1][0].year());
  });

  return (
    <div>
      <div className="calendar">
        {showEventModal && <EventModal />}

        {!location.pathname.includes("/user") && (
          <h1 className="pt-3 pb-2 md:hidden text-sm text-left text-gray-500 font-light">
            Evidenca opravljenih ur
          </h1>
        )}

        <CalendarHeader />

        <div className="calendar-wrapper flex flex-row">
          {!location.pathname.includes("/user") && (
            <div>
              <Sidebar />
            </div>
          )}

          <div className="calendar-content flex flex-1 flex-col mb-5 md:mb-0">
            <Month month={currenMonth} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Calendar;
