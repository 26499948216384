import dayjs from "dayjs";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalContext from "../../context/GlobalContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Calendar.css";
import { getMonth } from "../../util";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function CalendarHeader() {
  const { monthIndex, setMonthIndex } = useContext(GlobalContext);
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const params = useParams();
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const user = useSelector((state) => state.msalInstance.user);

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
    const temp = [];
    if (user != null) {
      temp.push(user);
    }
    setUsers(temp);
  }, [monthIndex]);

  async function exportReport() {
    const currentMonth = parseInt(localStorage.getItem("currentMonth"));
    const currentYear = parseInt(localStorage.getItem("currentYear"));
    const name = localStorage.getItem("name");

    await axios
      .post(`${process.env.REACT_APP_API_URL}/excel`, {
        month: currentMonth,
        year: currentYear,
        users: users,
        currentMonth: currenMonth,
      })
      .then((body) => {
        setTimeout(() => {
          if (body.data.success == true) {
            window.location.href =
              process.env.REACT_APP_API_URL +
              "/excel/download/" +
              user.name +
              "/" +
              currentMonth +
              "/" +
              currentYear;
          }
        }, 5000);
      });
  }

  return (
    <header className="calendar-header md:py-2 flex d-flex mb-3 md:mb-0 bg-white md:bg-transparent p-3 md:p-0">
      <div className="flex items-center flex-wrap">
        <h1 className="hidden md:block md:mr-10 mt-3 md:mt-0 text-sm md:text-xl text-gray-500 font-light">
          Evidenca opravljenih ur
        </h1>
        <button
          onClick={handleReset}
          className="border rounded py-2 px-4 md:mr-5 text-gray-600"
        >
          Danes
        </button>
        <button onClick={handlePrevMonth}>
          <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
            <ChevronLeftIcon />
          </span>
        </button>
        <button onClick={handleNextMonth}>
          <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
            <ChevronRightIcon />
          </span>
        </button>
        <h2 className="ml-4 text-md md:text-xl text-gray-500 font-medium">
          {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
        </h2>
      </div>
      {!location.pathname.includes("/user") && (
        <div className="mt-3 md:mt-0 md:ml-5 flex items-center download-wrapper">
          <Button
            className="download-btn"
            variant="transaprent"
            size="small"
            onClick={exportReport}
          >
            <FileDownloadIcon className="mr-1" />
            Izvozi poročilo
          </Button>
        </div>
      )}
    </header>
  );
}
