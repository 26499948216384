import React from "react";
import AuthContext from "../../store/auth-context";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  fetchEntries,
  resetEntries,
} from "../../store/redux/slices/entriesSlice";
import Calendar from "../Calendar/Calendar";
import Header from "../Header/Header";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Container from "@mui/material/Container";
import { getDateValue } from "../../services/EntryService";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HomePage = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const msal = useSelector((state) => state.msalInstance.msalInstance);
  const user = useSelector((state) => state.msalInstance.user);

  const userId = user.username;

  useEffect(() => {
    dispatch(fetchEntries(userId));

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    getDateValue()
  }, [dispatch]);

  return (
    <div className="bg-primary">
      <Header />

      {isLoading ? <LoadingSpinner /> : <Container maxWidth="xl pt-67"><Calendar /></Container>}

    </div>
  );
};
export default HomePage;
