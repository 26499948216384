import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "./store/auth-context";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import HomePage from "./components/Homepage/HomePage";
import AuthPage from "./pages/AuthPage";
import Admin from "./components/Admin/Admin";
import User from "./components/Admin/User";
import Report from "./components/Admin/Report";
import Check from "./components/Shared/Check";
import Auth from "./Auth";
import AuthCallback from "./components/Auth/AuthCallback";
import Navodila from "./components/Pdf/Navodila";

function App() {
  return (
    <div className="App notranslate">
      <Routes>
        <Route path="/" element={<Check />}></Route>
        <Route path="/auth-callback" element={<AuthCallback />} />

        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        

        <Route path="/zgodovina" element={<HomePage />}></Route>
        <Route path="/navodila" element={<Navodila />}></Route>
 

        {/* <Route path="/" element={<Navigate to="/" />}></Route> */}


        {/* <Route path="*" element={<Navigate to="/login" />} /> */}

        {/* <Route path="/" element={<Navigate to="/admin" />} /> */}

        <Route path="/admin" element={<Admin />} />
        <Route path="/user/:id" element={<User />} />

        <Route path="/report/:id" element={<Report />} />
      </Routes>
    </div>
  );
}

export default App;
