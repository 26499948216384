import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { fetchEntries } from "../../store/redux/slices/entriesSlice";
import axios from "axios";
import Header from "../Header/Header";
import Calendar from "../Calendar/Calendar";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import GlobalContext from "../../context/GlobalContext";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { getWorkingDays } from "../../services/EntryService";
import LunchesCorrection from "../Shared/LunchesCorrection";
import StimulationModal from "../Shared/StimulationModal";
import TransportationsCorrection from "../Shared/TransporationsCorrection";

const User = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = location.state.name;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPrenos, setOpenPrenos] = React.useState(false);
  const handleOpenPrenos = () => setOpenPrenos(true);
  const handleClosePrenos = () => setOpenPrenos(false);

  const [openLunchesCorrection, setOpenLunchesCorrection] =
    React.useState(false);
  const handleOpenLunchesCorrection = () => setOpenLunchesCorrection(true);
  const handleCloseLunchesCorrection = () => setOpenLunchesCorrection(false);

  const [openStimulationModal, setOpenStimulationModal] = React.useState(false);
  const handleOpenStimulationModal = () => setOpenStimulationModal(true);
  const handleCloseStimulationModal = () => setOpenStimulationModal(false);

  const [openTransportationModal, setOpenTransportationModal] =
    React.useState(false);
  const handleOpenTransportationModal = () => setOpenTransportationModal(true);
  const handleCloseTransportationModal = () =>
    setOpenTransportationModal(false);

  const { monthIndex, setMonthIndex } = useContext(GlobalContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [opravljeneUre, setOpravljeneUre] = useState(0);
  const [malice, setMalice] = useState(0);
  const [prevozi, setPrevozi] = useState(0);

  const [lunchesToDisplay, setLunchesToDisplay] = useState(null);
  const [transportationsToDisplay, setTransportationsToDisplay] =
    useState(null);

  const [parentState, setParentState] = useState(false);

  const [vseUre, setVseUre] = useState(0);
  const [nadure, setNadure] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [workingDays, setWorkingDays] = useState(0);
  const [overtimeToDisplay, setOvertimeToDisplay] = useState(null);
  const [overtimeTransferToDisplay, setOvertimeTransferToDisplay] =
    useState(null);

  const hoursCorrection = useRef(null);
  const hoursTransferValue = useRef(null);
  const user = useSelector((state) => state.msalInstance.user);

  const [editableStimulation, setEditableStimulation] = useState(0);

  function getMonthNumber(monthName) {
    const months = {
      januar: 1,
      februar: 2,
      marec: 3,
      april: 4,
      maj: 5,
      junij: 6,
      julij: 7,
      avgust: 8,
      september: 9,
      oktober: 10,
      november: 11,
      december: 12,
    };

    return months[monthName];
  }

  const refreshParentState = () => {
    setParentState(true);
  };

  useEffect(() => {
    const workingDaysCount = async () => {
      const workingDays = await getWorkingDays(
        parseInt(localStorage.getItem("currentYear")),
        parseInt(localStorage.getItem("currentMonth"))
      ).then((res) => {
        setWorkingDays(parseInt(res * 8));
      });
    };
    workingDaysCount();
  });

  useEffect(() => {
    dispatch(fetchEntries(userId));
    getUser();
  }, [dispatch, monthIndex, approvalStatus, parentState]);

  useEffect(() => {
    getEntries();
  });

  useEffect(() => {
    getOvertimeToTransfer();
  }, [currentUser]);

  useEffect(() => {
    checkApproveStatus();
  });

  const getEntries = async () => {
    try {
      const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
      const yearNumber = parseInt(localStorage.getItem("currentYear"));

      let role = null;

      if (currentUser !== null) {
        role = currentUser.role[0];
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/entries/getByMonth`,
        {
          username: location.state.name,
          monthNumber: monthNumber,
          yearNumber: yearNumber,
          role: role,
        }
      );

      const opravljeneUre = response.data.opravljeneUre;
      const vseUre = response.data.vseUre;
      const nadure = response.data.nadure;
      const malice = response.data.malice;
      const prevozi = response.data.prevozi;

      setOpravljeneUre(opravljeneUre);
      setMalice(malice);
      setPrevozi(prevozi);
      setVseUre(vseUre);
      setNadure(nadure);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkApproveStatus = async () => {
    if (location.state != undefined || location.state != null) {
      const username = location.state.name;
      const month = monthIndex + 1;
      const year = dayjs(new Date(dayjs().year(), monthIndex)).format("YYYY");

      setTransportationsToDisplay(null);
      setLunchesToDisplay(null);

      const monthNumber = getMonthNumber(
        dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM")
      );

      const status = await axios
        .post(process.env.REACT_APP_API_URL + "/approval-hours", {
          username: username,
          month: monthNumber,
          year: year,
        })
        .then((response) => {
          if (response.data != null) {
            setApprovalStatus(response.data.status);
            if (response.data.stimulation !== undefined) {
              setEditableStimulation(response.data.stimulation);
            } else {
              setEditableStimulation(0);
            }

            if (response.data.lunches !== undefined) {
              setLunchesToDisplay(response.data.lunches);
            }

            if (response.data.transportations !== undefined) {
              setTransportationsToDisplay(response.data.transportations);
            }

            if (response.data.overtimeHistory !== undefined) {
              setOvertimeToDisplay(response.data.overtimeHistory);
            }
          } else {
            if (currentUser != null) {
              setApprovalStatus(false);
              setEditableStimulation(0);
              setOvertimeToDisplay(currentUser.overtimeHours + nadure);
            }
          }

          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    }
  };

  const getOvertimeToTransfer = async () => {
    const username = location.state.name;
    const year = dayjs(new Date(dayjs().year(), monthIndex)).format("YYYY");

    const month = getMonthNumber(
      dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM")
    );

    const log = await axios
      .post(`${process.env.REACT_APP_API_URL}/overtime-log`, {
        employee: username,
        month: month,
        year: year,
      })
      .then((docs) => {
        if (docs.data && docs.data.overtimePrenos) {
          setOvertimeTransferToDisplay(docs.data.overtimePrenos);
        }
      });
  };

  const getUser = async () => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}/users/${userId}`)
      .then((body) => {
        setCurrentUser(body.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const approveHours = async () => {
    const username = location.state.name;
    const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
    const yearNumber = parseInt(localStorage.getItem("currentYear"));
    const today = new Date();

    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/approval-hours/approve`, {
        username: location.state.name,
        month: monthNumber,
        year: yearNumber,
        status: true,
        date: today,
      })
      .then(async (response) => {
        if (response.status == 201) {
          setApprovalStatus(true);

          const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
            username: user.username,
            employee: location.state.name,
            date: today,
            action: "hours confirmed",
            system: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const status = await axios
      .post(process.env.REACT_APP_API_URL + "/approval-hours", {
        username: username,
        month: monthNumber,
        year: yearNumber,
      })
      .then(async (response) => {
        if (!response.data.overtimeHoursApproved) {
          const overtimeHours = await axios
            .get(`${process.env.REACT_APP_API_URL}/users/${userId}`)
            .then((body) => {
              return body.data.overtimeHours;
            });

          const newOverTimeHours = overtimeHours + nadure;

          const response2 = await axios
            .patch(`${process.env.REACT_APP_API_URL}/users`, {
              username: location.state.name,
              hoursCorrection: newOverTimeHours,
            })
            .then(async (response) => {
              const overtimeHoursApproved = await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/approval-hours/approve`,
                  {
                    username: location.state.name,
                    month: monthNumber,
                    year: yearNumber,
                    status: true,
                    date: today,
                    lunches: malice,
                    transportations: prevozi,
                    overtimeHoursApproved: true,
                    overtimeHistory: newOverTimeHours,
                  }
                )
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });
            });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getUser();
      });
  };

  const cancelHours = async () => {
    const today = new Date();
    const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
    const yearNumber = parseInt(localStorage.getItem("currentYear"));

    const response = await axios
      .patch(`${process.env.REACT_APP_API_URL}/approval-hours/cancel`, {
        username: location.state.name,
        month: monthNumber,
        year: yearNumber,
        date: today,
        status: false,
      })
      .then(async (response) => {
        if (response.status == 201) {
          setApprovalStatus(false);

          const res = await axios.post(`${process.env.REACT_APP_API_URL}/log`, {
            username: user.username,
            employee: location.state.name,
            date: today,
            action: "hours unconfirmed",
            system: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const overtimeTransfer = async () => {
    const month = parseInt(localStorage.getItem("currentMonth")) + 1;
    const year = parseInt(localStorage.getItem("currentYear"));

    const overtimeHours = await axios
      .get(`${process.env.REACT_APP_API_URL}/users/${userId}`)
      .then((body) => {
        return body.data.overtimeHours;
      });

    if (hoursTransferValue.current) {
      const overtimeForTransfer =
        hoursTransferValue.current.querySelector("input").value;

      const overtimeIzplaciloPotni = overtimeHours - overtimeForTransfer;

      const response = await axios
        .patch(`${process.env.REACT_APP_API_URL}/users`, {
          username: location.state.name,
          hoursCorrection: overtimeForTransfer,
        })
        .then(async (response) => {
          getUser();

          const log1 = await axios.post(
            `${process.env.REACT_APP_API_URL}/overtime-log/transfer`,
            {
              username: user.username,
              employee: location.state.name,
              month: month,
              year: year,
              overtimeIzplaciloPotni: overtimeIzplaciloPotni,
              overtimePrenos: overtimeForTransfer,
            }
          );
        })
        .finally(() => {
          handleClosePrenos();
        });
    }
  };

  const overtimeCorrection = async () => {
    const today = new Date();
    const month = parseInt(localStorage.getItem("currentMonth")) + 1;
    const year = parseInt(localStorage.getItem("currentYear"));

    const overtimeHours = await axios
      .get(`${process.env.REACT_APP_API_URL}/users/${userId}`)
      .then((body) => {
        return body.data.overtimeHours;
      });

    if (hoursCorrection.current) {
      const hoursCorrectionInput =
        hoursCorrection.current.querySelector("input").value;
      const newOverTimeHours = overtimeHours - hoursCorrectionInput;

      const response = await axios
        .patch(`${process.env.REACT_APP_API_URL}/users`, {
          username: location.state.name,
          hoursCorrection: newOverTimeHours,
        })
        .then(async (response) => {
          if (response != null) {
            getUser();

            const log1 = await axios.post(
              `${process.env.REACT_APP_API_URL}/overtime-log/payment`,
              {
                username: user.username,
                employee: location.state.name,
                month: month,
                year: year,
                overtimeIzplacilo: parseInt(hoursCorrectionInput),
              }
            );

            const log2 = await axios.post(
              `${process.env.REACT_APP_API_URL}/log`,
              {
                username: user.username,
                employee: location.state.name,
                date: today,
                action: "correction overtime",
                overtime_correction: hoursCorrectionInput,
                overtime_previous: overtimeHours,
                overtime_new: newOverTimeHours,
                day: today.valueOf(),
                system: false,
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <div>
      <Header />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="bg-primary pt-67">
          <Container maxWidth="xl">
            <div className="md:flex flex-row">
              <h1 className="md:mr-10 pt-3 md:hidden text-sm md:text-xl text-gray-500 font-light text-left">
                Evidenca opravljenih ur
              </h1>
              <div className="pt-3 md:pt-4">
                <aside className="aside card p-5 md:w-64 mb-3 md:mr-3 text-left">
                  <div className="mb-5">
                    {currentUser && (
                      <div className="mb-5">
                        <h3 className="font-medium text-xl">
                          {currentUser.displayName}
                        </h3>
                      </div>
                    )}

                    <div className="flex flex-row justify-between">
                      <p>Status: </p>
                      {!approvalStatus && (
                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          Nepotrjeno
                        </span>
                      )}

                      {approvalStatus && (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Potrjeno
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row justify-between">
                    <p>Opravljene ure:</p>
                    <p>{opravljeneUre}</p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>Vse ure (fond):</p>
                    <p>{parseInt(workingDays)}</p>
                  </div>
                  {malice !== null && (
                    <div className="flex flex-row justify-between">
                      <p>Malice:</p>
                      <p>
                        {lunchesToDisplay !== null
                          ? lunchesToDisplay
                          : parseInt(malice)}
                      </p>
                    </div>
                  )}

                  {prevozi !== null && (
                    <div className="flex flex-row justify-between">
                      <p>Prevozi:</p>
                      <p>
                        {transportationsToDisplay !== null
                          ? transportationsToDisplay
                          : parseInt(prevozi)}
                      </p>
                    </div>
                  )}

                  <div className="flex flex-row justify-between mt-5">
                    <p>Nadure tekoči mesec:</p>
                    <p>{nadure}</p>
                  </div>

                  {overtimeToDisplay != null && (
                    <div className="flex flex-row justify-between">
                      <p>Nadure skupaj:</p>
                      <p>{overtimeToDisplay}</p>
                    </div>
                  )}

                  {overtimeTransferToDisplay != null && (
                    <div className="flex flex-row justify-between mt-2">
                      <p className="text-sm">Nadure za prenos:</p>
                      <p className="text-sm">{overtimeTransferToDisplay}</p>
                    </div>
                  )}

                  {user.idTokenClaims.roles.includes("Admin") && (
                    <div className="flex flex-row justify-between mt-5">
                      <p>Stimulacija:</p>
                      <div className="text-right">
                        <p>
                          {editableStimulation}
                          <span> %</span>
                        </p>
                      </div>
                    </div>
                  )}

                  {user.idTokenClaims.roles.includes("Admin") &&
                  !approvalStatus ? (
                    <div className="mt-4">
                      {location.pathname.includes("/user") && (
                        <div>
                          <Button
                            className="btn-green w-full"
                            variant="contained"
                            color="success"
                            onClick={approveHours}
                          >
                            Potrdi ure
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mt-4">
                      {approvalStatus && (
                        <div className="mb-1">
                          <div>
                            <Button
                              className="btn-green w-full"
                              variant="contained"
                              color="success"
                              onClick={handleOpen}
                            >
                              Izplačilo nadur
                            </Button>
                          </div>

                          <div className="mt-1">
                            <Button
                              className="btn-green w-full"
                              variant="contained"
                              color="success"
                              onClick={handleOpenStimulationModal}
                            >
                              Dodaj stimulacijo
                            </Button>
                          </div>

                          <div className="mt-1">
                            <Button
                              className="transform-none w-full"
                              variant="outlined"
                              color="success"
                              onClick={handleOpenPrenos}
                            >
                              Prenos nadur
                            </Button>
                          </div>

                          <div className="mt-1">
                            <Button
                              className="transform-none w-full"
                              variant="outlined"
                              color="success"
                              onClick={handleOpenLunchesCorrection}
                            >
                              Korekcija malic
                            </Button>
                          </div>

                          <div className="mt-1">
                            <Button
                              className="transform-none w-full"
                              variant="outlined"
                              color="success"
                              onClick={handleOpenTransportationModal}
                            >
                              Korekcija prevozov
                            </Button>
                          </div>
                        </div>
                      )}

                      {location.pathname.includes("/user") && (
                        <Button
                          className="w-full btn-red"
                          variant="contained"
                          onClick={cancelHours}
                        >
                          Prekliči ure
                        </Button>
                      )}
                    </div>
                  )}
                </aside>
              </div>
              <div className="flex-1 flex-col">
                <Calendar />
              </div>
            </div>
          </Container>
        </div>
      )}

      <LunchesCorrection
        open={openLunchesCorrection}
        onClose={() => {
          handleCloseLunchesCorrection();
          refreshParentState();
        }}
      />

      <TransportationsCorrection
        open={openTransportationModal}
        onClose={() => {
          handleCloseTransportationModal();
          refreshParentState();
        }}
      />

      <StimulationModal
        open={openStimulationModal}
        onClose={() => {
          handleCloseStimulationModal();
          refreshParentState();
        }}
      />

      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="more-options-dialog md:w-1/2 m-auto"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Korekcija nadur
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <div>
            <TextField
              required
              id="outlined-required"
              type="number"
              label="Nadure za izplačilo"
              variant="filled"
              className="w-full"
              ref={hoursCorrection}
            />
            <div className="mt-2">
              <Button
                className="btn w-full primary-btn"
                onClick={overtimeCorrection}
              >
                Potrdi
              </Button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        open={openPrenos}
        onClose={handleClosePrenos}
        aria-labelledby="customized-dialog-title"
        className="more-options-dialog md:w-1/2 m-auto"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Prenos nadur
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClosePrenos}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <div>
            <TextField
              required
              id="outlined-required"
              type="number"
              label="Prenos nadur"
              variant="filled"
              className="w-full"
              ref={hoursTransferValue}
            />
            <div className="mt-2">
              <Button
                className="btn w-full primary-btn"
                onClick={overtimeTransfer}
              >
                Potrdi
              </Button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default User;
